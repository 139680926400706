.tableWrapper {
  max-height: 34rem;
  overflow: auto;
  border-radius: 6px;
}
.tableWrapper::-webkit-scrollbar {
  width: 0.5rem;
}
.tableWrapper::-webkit-scrollbar-track {
  background: var(--table-hover-color);
  border-radius: var(--border-radius);
}
.tableWrapper::-webkit-scrollbar-thumb {
  background: var(--table-header-color);
  border-radius: var(--border-radius);
}
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  background-color: var(--table-color);
}
.table thead{
  /* Scrollable */
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
}
.table tfoot{
  /* Scrollable */
  position: sticky;
  position: -webkit-sticky;
  bottom: -1px;
}
.table th {
  padding: 1rem;
  font-weight: 700;
  font-size: 0.7rem;
  text-align: left;
  border-bottom: 1px solid #e1e4e8;
  background-color: var(--table-header-color);
  color: var(--text-color);
}
.table td {
  padding: 0.6rem 1rem;
  font-size: 0.7rem;
  text-align: left;
  border-bottom: 1px solid #e1e4e8;
}
.table tr:hover {
  background-color: var(--table-hover-color);
}
.table tfoot{
  background-color: var(--table-header-color);
}
.table tfoot tr:hover{
  background-color: var(--table-header-color);
}