@font-face {
  font-family: "Poppins";
  src: url('./components/typography/fonts/poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url('./components/typography/fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url('./components/typography/fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url('./components/typography/fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url('./components/typography/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url('./components/typography/fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url('./components/typography/fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url('./components/typography/fonts/poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

:root{
  /* PALLETE DASHBOARD */
  --p1: #f3f4f2;
  --p2: #030303;
  --p3: #efd4ba;
  --p4: #abcdab;
  --p5: #434237;
  --p6: #a0a39f;

  /* Actual colors */
  --color-green: #eefcef;
  --color-orange: #ffeee2;
  --color-blue: #e6f5fa;
  --color-purple: #f4f5f9;
  --color-brown: #f9f8f3;
  --color-white: #ffffff;
  --color-black: #000000;

  /* Order Card Colors */
  --order-pending: #f7c2a3;
  --order-shipped: #cbd3ed;
  --order-confirmed: #c0ecb4;
  --order-delivered: var(--color-green);
  --order-canceled: #f79494;

  --error: #B20D30;
  /* --error: #DF2935; */
  --info: #4d808a;
  --warning: #EEBA0B;

  /* Properties sizes */
  --border-radius: .9rem;

  /* Theme-1 */
  --black: var(--p2);
  --light: var(--p1);

  --text-color: var(--p2);
  --label-color: var(--p5);

  --btn-border-color: var(--p2);

  --bg-color-light: var(--p1);
  
  --danger-color: var(--error);
  --danger-text-color: var(--color-white);

  --table-color: var(--color-white);
  --table-header-color: var(--p4);
  --table-hover-color: var(--color-green);
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: Poppins, sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  font-size: 10px;
}