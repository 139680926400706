.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
  line-height: 1.2;
  color: var(--color-black);
}
.h1 {
  font-size: 2.5rem;
}
.h2 {
  font-size: 2rem;
}
.h3 {
  font-size: 1.5rem;
}
.h4 {
  font-size: 1.2rem;
}
.h5 {
  font-size: 1rem;
}
.h6 {
  font-size: 0.8rem;
}
/* Margins */
.marginNone {
  margin: 0;
}
.marginSmall {
  margin: 0.5rem 0;
}
.marginMedium {
  margin: 0.7rem 0;
}
.marginLarge {
  margin: 0.9rem 0;
}
.marginMax {
  margin: 2.8rem 0;
}
.marginMaxTop {
  margin-top: 2.8rem;
}
.marginMaxBottom {
  margin-bottom: 2.8rem;
}
/* Align Texts */
.alignLeft {
  text-align: left;
}
.alignCenter {
  text-align: center;
}
.alignRight {
  text-align: right;
}
/* Colors */
.colorLight {
  color: var(--p6);
}
.colorWhite {
  color: var(--color-white);
}
/* Font Weight */
.fontWeightLight {
  font-weight: 400;
}
.fontWeightMedium {
  font-weight: 600;
}
.fontWeightBold {
  font-weight: 800;
}
/* Hover Styles */
.hover:hover {
  cursor: pointer;
  text-decoration: underline;
}
