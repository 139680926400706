.placeholder{
  width: 14rem;
  height: 18rem;
  border: 2px dashed var(--p6);
  border-radius: var(--border-radius);
  align-items: center;
  justify-content: center;
}
.placeholder > img{
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius);
  object-fit: cover;
  cursor: pointer;
}
.placeholder > p{
  font-size: .9rem;
  line-height: 1.6;
  color: var(--p5);
  text-align: center;
}
.placeholder > p > span{
  color: var(--info);
  cursor: pointer;
}
.error{
  color: var(--danger-color);
  margin-top: 0.2rem;
  font-weight: 500;
  font-size: 0.7rem;
}