.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-stretch {
  align-items: stretch;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.flex-wrap {
  flex-wrap: wrap;
}
/* Flex Direction */
.row {
  flex-direction: row;
  gap: 1.5rem;
}
.col {
  flex-direction: column;
  gap: 0.5rem;
}
/* Flex Direction */
/* Display */
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.fullWidth {
  width: 100%;
}
/* Self */
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-baseline {
  align-self: baseline;
}
.self-stretch {
  align-self: stretch;
}

