.form__group {
  padding: 0.4rem 0;
  text-align: right;
}
.form__group input {
  width: 100%;
}
.form__group select {
  width: 100%;
}
.alignLeft {
  text-align: left !important;
}
