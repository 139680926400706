.input__box {
  display: flex;
  align-items: center;
  border: 1px solid #aaa;
  border-radius: var(--border-radius);
  width: fit-content;
}
.input__box > ion-icon {
  margin-left: 0.6rem;
  font-size: 1.6rem;
}
.input {
  border: none;
  background: none;
  padding: 0.9rem 0.6rem;
  width: 100%;
  border-radius: var(--border-radius);
}
.fullWidth {
  width: 100% !important;
}
.input:focus {
  outline: none;
  border-color: #888;
}
.error {
  color: var(--danger-color);
  margin-top: 0.2rem;
  font-weight: 500;
  font-size: 0.7rem;
}
.message {
  color: var(--info);
  margin-top: 0.2rem;
  font-weight: 500;
  font-size: 0.7rem;
}
/* Labels */
.label {
  font-size: 0.7rem;
  font-weight: 500;
  color: var(--label-color);
}
/* Buttons */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Extras */
  gap: .5rem;
  border: none;
  border-radius: var(--border-radius);
  padding: 0.8rem 1.2rem;
  color: var(--light);
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 0.7rem;
}
.button_primary-light {
  border: 1px solid var(--btn-border-color);
  background-color: var(--color-white);
  color: var(--text-color);
}
.button_secondary-light {
  border: 1px solid var(--btn-border-color);
  background-color: var(--black);
}
/* .button_primary-dark{
    background-color: var(--primary-color-dark);
}
.button_secondary-dark{
    background-color: var(--secondary-color-dark);
} */
.button_danger {
  background-color: var(--danger-color);
  color: var(--danger-text-color);
}
.button_disabled {
  filter: brightness(0.8);
  cursor: not-allowed;
}
/* Link & Navlink */
.navlink:link,
.navlink:visited {
  color: var(--black);
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.2;
  display: flex;
  align-items: flex-end;
}
.navlink:hover > ion-icon,
.navlink:active > ion-icon {
  color: var(--p6);
}
.navlink > ion-icon {
  margin-left: 2rem;
  margin-right: 1.2rem;
  font-size: 1.2rem;
}

.link:link,
.link:visited {
  color: var(--black);
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 0.2rem 0;
  display: flex;
  align-items: flex-end;
}
.link:hover,
.link:active {
  text-decoration: underline;
}
.link:hover > ion-icon,
.link:active > ion-icon {
  color: var(--p6);
}
.link > ion-icon {
  margin-right: 1.2rem;
  font-size: 1.2rem;
}

/* Link & Navlink */

/* Select */
.select {
  border: 1px solid #aaa;
  border-radius: var(--border-radius);
  width: fit-content;
  padding: 0.9rem 0.6rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.select:focus {
  outline: none;
  border-color: #888;
}
/* Select */
/* Para */
.para {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.6;
  color: var(--info);
}
/* Para */
/* TextArea */
.textarea {
  border: 1px solid #aaa;
  border-radius: var(--border-radius);
  padding: 0.9rem 0.6rem;
  width: 100%;
  height: 12rem;
  resize: vertical;
  outline: none;
}
/* TextArea */
/* TAGS */
.tag{
    align-items: center;
    justify-content: center;
    width: .8rem;
    height: .8rem;
    background-color: var(--danger-color);
    border-radius: var(--border-radius);
    color: var(--color-white);
    font-size: 0.7rem;
    cursor: pointer;
    position: absolute;
    top: -.45rem;
    right: -.3rem;
}
/* TAGS */
/* UTILITIES */
.hide{display: none;}
.flex{display: flex;}
.block{display: block;}
/* UTILITIES */