.container{
  display: flex;
}
.row{
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.alignCenter{
  align-items: center;
}
.col{
  display: flex;
  flex-direction: column;
  gap: .5rem;
}