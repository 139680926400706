.dashboard {
  display: flex;

  /* Dimensions */
  min-height: 100vh;
  width: 100%;
}
.container {
  /* Dimensions */
  margin-top: 1.5rem;
}
/* OVERFLOW X and Y */
.overflow_y{
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 2.8rem;
}
.overflow_y::-webkit-scrollbar {
  width: 0.35rem;
  height: 0.35rem;
}
.overflow_y::-webkit-scrollbar-track {
  border-radius: var(--border-radius);
}
.overflow_y::-webkit-scrollbar-thumb {
  background: var(--p6);
  border-radius: var(--border-radius);
}
.overflow_y::-webkit-scrollbar-thumb:hover {
  background: var(--p5);
}
/* OVERFLOW X and Y */
.customer,
.product,
.order {
  display: flex;
  gap: 1rem;
  flex: 1 1 auto;
  justify-content: space-between;
}
.customer > h6,
.product > h6,
.order > h6 {
  margin-bottom: 0.7rem;
}
.productIcon{
  display: flex;
  align-items: center;
}
.productIcon > img{
  width: 1.8rem;
  border-radius: 7px;
  margin-right: 0.5rem;
}
