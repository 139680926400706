.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* dimensions */
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
  /* extra */
  /* background-color: var(--bg-color-light); */
  background-color: var(--color-white);
}
